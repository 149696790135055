import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../lib/contextLib';
import { onError } from '../lib/errorLib';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { userHasAuthenticated } = useAppContext();

  const history = useHistory();

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSetEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleSetPassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail('');
    setPassword('');

    try {
      await Auth.signIn(email, password);
      userHasAuthenticated(true);
      history.push('/');
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  };

  return (
    <div className='Login'>
      <Form onSubmit={handleSubmit}>
        <Form.Group size='lg' controlId='email'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type='email'
            value={email}
            onChange={handleSetEmail}
          />
        </Form.Group>
        <Form.Group size='lg' controlId='password'>
          <Form.Control
            type='password'
            value={password}
            onChange={handleSetPassword}
          />
        </Form.Group>
        <LoaderButton
          block
          size='lg'
          type='submit'
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </Form>
    </div>
  );
};

export default Login;
