import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useAppContext } from '../lib/contextLib';
import { onError } from '../lib/errorLib';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Collapse,
  Tooltip,
  tooltipClasses,
  styled,
} from '@mui/material';
import config from '../config';
import './Home.css';
import { Euro, Dvr, Square, ExpandLess, ExpandMore } from '@mui/icons-material';

const Home = () => {
  const [data, setData] = useState([]);
  const [sales, setSales] = useState([]);
  const [customerSegments, setCustomerSegments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showImportantCustomer, setShowImportantCustomers] = useState(false);
  const [mainKeywords, setMainKeywords] = useState([]);
  const [salesOpen, setSalesOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const [customersOpen, setCustomersOpen] = useState(false);
  const { isAuthenticated } = useAppContext();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    async function loadData() {
      try {
        const data = await getCases();
        setData(data);
      } catch (e) {
        onError(e);
      }
    }
    loadData();
  }, [isAuthenticated]);

  useEffect(() => {
    if (data.length === 0) {
      return;
    } else {
      setSales(data.sales);
      setProjects(data.projects);
      setMainKeywords(data.mainKeywords.keywords);
      setCustomerSegments(data.customerSegments);
      setTableData([...data.sales, ...data.projects]);
    }
  }, [data]);

  const handleSalesClick = () => {
    setSalesOpen(!salesOpen);
  };

  const handleProjectsClick = () => {
    setProjectsOpen(!projectsOpen);
  };

  const handleCustomersClick = () => {
    setCustomersOpen(!customersOpen);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function getCases() {
    return API.post('severaData', '/severa/token');
  }

  const returnedSales = sales.reduce((accumulator, salesObject) => {
    const foundObject = accumulator.find(
      (object) => object.name === salesObject.customer.name
    );
    if (!foundObject) {
      accumulator.push({
        name: salesObject.customer.name,
        salesCases: [
          {
            salesName: salesObject.name,
            customerName: salesObject.customer.name,
            guid: salesObject.guid,
            salesStatus: salesObject.salesStatus,
            keywords: salesObject.keywords ? salesObject.keywords : [],
          },
        ],
      });
    } else {
      if (salesObject.keywords) {
        foundObject.salesCases = [
          ...foundObject.salesCases,
          {
            salesName: salesObject.name,
            customerName: salesObject.customer.name,
            guid: salesObject.guid,
            salesStatus: salesObject.salesStatus,
            keywords: salesObject.keywords,
          },
        ];
      }
    }
    return accumulator;
  }, []);

  const returnedProjects = projects.reduce((accumulator, projectObject) => {
    const foundObject = accumulator.find(
      (object) => object.name === projectObject.customer.name
    );
    if (!foundObject) {
      if (!projectObject.keywords) {
        accumulator.push({
          name: projectObject.customer.name,
          guid: projectObject.guid,
          projectName: projectObject.name,
          keywords: [],
        });
      } else {
        accumulator.push({
          name: projectObject.customer.name,
          guid: projectObject.guid,
          projectName: projectObject.name,
          keywords: [...projectObject.keywords],
        });
      }
    } else {
      if (projectObject.keywords) {
        foundObject.keywords = [
          ...foundObject.keywords,
          ...projectObject.keywords,
        ];
      }
    }
    return accumulator;
  }, []);

  const returnedArray = tableData.reduce((accumulator, customerObject) => {
    const foundObject = accumulator.find(
      (object) => object.guid === customerObject.customer.guid
    );
    // IF no object --> add new object
    if (!foundObject) {
      if (!customerObject.keywords) {
        accumulator.push({
          guid: customerObject.customer.guid,
          name: customerObject.customer.name,
          keywords: [],
        });
      } else {
        accumulator.push({
          guid: customerObject.customer.guid,
          name: customerObject.customer.name,
          keywords: [...customerObject.keywords],
        });
      }
    } else {
      if (customerObject.keywords) {
        foundObject.keywords = [
          ...foundObject.keywords,
          ...customerObject.keywords,
        ];
      }
    }
    return accumulator;
  }, []);

  const returnedCustomerSegments = customerSegments.map((customer) => {
    return {
      guid: customer.customer.guid,
      name: customer.customer.name,
      accountType: customer.marketSegment.name,
    };
  });

  returnedArray.forEach((customer) => {
    const findCustomerObject = returnedCustomerSegments.find(
      (c) => c.guid === customer.guid
    );
    if (findCustomerObject) {
      customer.accountType = findCustomerObject.accountType;
      if (customer.accountType === 'Key Account') {
        customer.value = 4;
      } else if (customer.accountType === 'Potential Key Account') {
        customer.value = 3;
      } else if (customer.accountType === 'Regular Account') {
        customer.value = 2;
      } else if (customer.accountType === 'Inactive') {
        customer.value = 1;
      }
    }
  });

  const importantWithNoMainKeywords = returnedArray.filter((customer) => {
    const noMatchingKeyword = !customer.keywords.find((k) => {
      return mainKeywords.find((m) => m.guid === k.guid);
    });
    return noMatchingKeyword;
  });

  // Array.filter(x => x.accountType == 'foo').forEach((customer) => )

  const filteredArray = returnedArray.filter((customer) => {
    //console.log(customer);
    // check whether this customer has any sales / projects cases with any of the `mainKeywords`

    const matchingKeyword = customer.keywords.find((k) => {
      return mainKeywords.find((m) => m.guid === k.guid);
    });
    return matchingKeyword;
  });

  importantWithNoMainKeywords
    .filter((customer) => customer.value === 4)
    .forEach((keyCustomer) => {
      filteredArray.push(keyCustomer);
    });

  filteredArray.sort((a, b) => {
    if (a.value < b.value) {
      return 1;
    } else if (a.value > b.value) {
      return -1;
    } else if (a.value === undefined) {
      return 1;
    } else if (b.value === undefined) {
      return -1;
    } else {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const collectAllSalesCases = [];

  returnedSales.map((customer) => {
    return collectAllSalesCases.push(...customer.salesCases);
  });

  const salesWithNoMainKeyword = collectAllSalesCases.filter(
    (saleCase) =>
      !saleCase.keywords.find((k) => {
        return mainKeywords.find((m) => m.guid === k.guid);
      })
  );

  const projectsWithNoMainKeyword = returnedProjects.filter(
    (customer) =>
      !customer.keywords.find((k) => {
        return mainKeywords.find((m) => m.guid === k.guid);
      })
  );

  const customersNotClassified = filteredArray.filter((customer) => {
    return customer.accountType === undefined;
  });

  const getSales = (customer, keywordGuid) => {
    let saleNumber = 0;
    let saleCaseName;
    const saleResult = returnedSales.find(
      (sale) => sale.name === customer.name
    );

    if (!saleResult) {
      saleNumber = 0;
    } else {
      saleResult.salesCases.filter((saleCase) => {
        if (
          saleCase.keywords.find((keyword) => keyword.guid === keywordGuid) &&
          saleCase.salesStatus.name === 'Qualifying' &&
          saleNumber < 1
        ) {
          saleNumber = 1;
          saleCaseName = saleCase.salesName;
        } else if (
          saleCase.keywords.find((keyword) => keyword.guid === keywordGuid) &&
          saleCase.salesStatus.name === 'Building' &&
          saleNumber < 2
        ) {
          saleNumber = 2;
          saleCaseName = saleCase.salesName;
        } else if (
          saleCase.keywords.find((keyword) => keyword.guid === keywordGuid) &&
          saleCase.salesStatus.name === 'Closing' &&
          saleNumber < 3
        ) {
          saleNumber = 3;
          saleCaseName = saleCase.salesName;
        }
      });
    }

    if (saleNumber === 3) {
      return (
        <BootstrapTooltip title={`${saleCaseName}`}>
          <div className='keywordClosing borderRight'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else if (saleNumber === 2) {
      return (
        <BootstrapTooltip title={`${saleCaseName}`}>
          <div className='keywordBuilding borderRight'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else if (saleNumber === 1) {
      return (
        <BootstrapTooltip title={`${saleCaseName}`}>
          <div className='keywordQualifying borderRight'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else {
      return <div className='keywordNotFound borderRight'>&nbsp;</div>;
    }
  };

  const getProjects = (customer, keywordGuid) => {
    let projectNumber;
    let projectCaseName;
    const projectResult = returnedProjects.find(
      (project) => project.name === customer.name
    );
    if (!projectResult) {
      projectNumber = 0;
    } else {
      if (
        projectResult.keywords.find((keyword) => keyword.guid === keywordGuid)
      ) {
        projectNumber = 1;
        projectCaseName = projectResult.projectName;
      } else {
        projectNumber = 0;
      }
    }
    if (projectNumber === 1) {
      return (
        <BootstrapTooltip title={`${projectCaseName}`}>
          <div className='keywordFound'>&nbsp;</div>
        </BootstrapTooltip>
      );
    } else {
      return <div className='keywordNotFound'>&nbsp;</div>;
    }
  };

  const showOnlyImportantCustomers = () => {
    setShowImportantCustomers(!showImportantCustomer);
  };

  return (
    <div className='Home'>
      <input type='checkbox' onChange={showOnlyImportantCustomers} />
      <label className='inputLabel'>
        <b>Show only key accounts</b>
      </label>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  paddingRight: '150px',
                  color: 'white',
                }}
              >
                &nbsp;
              </TableCell>
              {filteredArray.length === 0 ? (
                <TableCell>Loading...</TableCell>
              ) : (
                filteredArray.map((item, i) => {
                  if (item.value === 4) {
                    return (
                      <TableCell style={{ backgroundColor: '#C6A2E8' }} key={i}>
                        <p>{item.name}</p>
                      </TableCell>
                    );
                  } else if (item.value === 3 && !showImportantCustomer) {
                    return (
                      <TableCell
                        style={{ backgroundColor: 'lightsteelblue' }}
                        key={i}
                      >
                        <p>{item.name}</p>
                      </TableCell>
                    );
                  } else if (item.value === 2 && !showImportantCustomer) {
                    return (
                      <TableCell
                        style={{ backgroundColor: 'lightcyan' }}
                        key={i}
                      >
                        <p>{item.name}</p>
                      </TableCell>
                    );
                  } else if (!showImportantCustomer) {
                    return (
                      <TableCell
                        key={i}
                        style={{ backgroundColor: 'antiquewhite' }}
                      >
                        <p>{item.name}</p>
                      </TableCell>
                    );
                  }
                })
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                padding={'none'}
                style={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                }}
              >
                &nbsp;
              </TableCell>
              {filteredArray.map((item, i) => {
                if (item.value === 4) {
                  return (
                    <TableCell
                      key={i}
                      style={{ color: 'gray', backgroundColor: '#C6A2E8' }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div
                          className='icon'
                          style={{ textAlign: 'center', width: '50%' }}
                        >
                          {<Euro fontSize='small' />}
                        </div>
                        <div
                          className='icon'
                          style={{
                            marginLeft: 'auto',
                            textAlign: 'center',
                            width: '50%',
                          }}
                        >
                          {<Dvr fontSize='small' />}
                        </div>
                      </div>
                    </TableCell>
                  );
                } else if (item.value === 3 && !showImportantCustomer) {
                  return (
                    <TableCell
                      key={i}
                      style={{
                        color: 'gray',
                        backgroundColor: 'lightsteelblue',
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div
                          className='icon'
                          style={{ textAlign: 'center', width: '50%' }}
                        >
                          {<Euro fontSize='small' />}
                        </div>
                        <div
                          className='icon'
                          style={{
                            marginLeft: 'auto',
                            textAlign: 'center',
                            width: '50%',
                          }}
                        >
                          {<Dvr fontSize='small' />}
                        </div>
                      </div>
                    </TableCell>
                  );
                } else if (item.value === 2 && !showImportantCustomer) {
                  return (
                    <TableCell
                      key={i}
                      style={{ color: 'gray', backgroundColor: 'lightcyan' }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div
                          className='icon'
                          style={{ textAlign: 'center', width: '50%' }}
                        >
                          {<Euro fontSize='small' />}
                        </div>
                        <div
                          className='icon'
                          style={{
                            marginLeft: 'auto',
                            textAlign: 'center',
                            width: '50%',
                          }}
                        >
                          {<Dvr fontSize='small' />}
                        </div>
                      </div>
                    </TableCell>
                  );
                } else if (!showImportantCustomer) {
                  return (
                    <TableCell
                      key={i}
                      style={{ color: 'gray', backgroundColor: 'antiquewhite' }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div
                          className='icon'
                          style={{ textAlign: 'center', width: '50%' }}
                        >
                          {<Euro fontSize='small' />}
                        </div>
                        <div
                          className='icon'
                          style={{
                            marginLeft: 'auto',
                            textAlign: 'center',
                            width: '50%',
                          }}
                        >
                          {<Dvr fontSize='small' />}
                        </div>
                      </div>
                    </TableCell>
                  );
                }
              })}
            </TableRow>
            {filteredArray.length === 0 ? (
              <TableRow>
                <TableCell>-</TableCell>
              </TableRow>
            ) : (
              mainKeywords.map((keyword) => {
                return (
                  <TableRow key={keyword.guid}>
                    <TableCell
                      padding={'none'}
                      style={{
                        position: 'sticky',
                        left: 0,
                        backgroundColor: 'white',
                      }}
                    >
                      {keyword.name}
                    </TableCell>
                    {filteredArray.map((customer, i) => {
                      if (customer.value === 4) {
                        return (
                          <TableCell
                            key={i}
                            padding={'none'}
                            style={{
                              borderLeft: '1px solid gray',
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              {getSales(customer, keyword.guid)}

                              {getProjects(customer, keyword.guid)}
                            </div>
                          </TableCell>
                        );
                      } else if (!showImportantCustomer) {
                        return (
                          <TableCell
                            key={i}
                            padding={'none'}
                            style={{
                              borderLeft: '1px solid gray',
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              {getSales(customer, keyword.guid)}

                              {getProjects(customer, keyword.guid)}
                            </div>
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className='colorExplanations'>
        <div className='customerColorExplanation'>
          <div className='customerColumn'>Customer account type</div>
          <div>{<Square style={{ color: '#C6A2E8' }} />} Key account</div>
          <div>
            {<Square style={{ color: 'lightsteelblue' }} />} Potential key
            account
          </div>
          <div>{<Square style={{ color: 'lightcyan' }} />} Regular account</div>
          <div>
            {<Square style={{ color: 'antiquewhite' }} />} Not classified
          </div>
        </div>
        <div className='column-explanation'>
          <div className='salesColumnExplanation'>
            <div className='salesColumnHeader'>{<Euro />} Sales column</div>
            <div>
              {<Square style={{ color: '#8bbd78' }} fontSize='medium' />}{' '}
              Closing
            </div>
            <div>
              {
                <Square
                  style={{ color: 'rgb(247, 189, 83)' }}
                  fontSize='medium'
                />
              }{' '}
              Building
            </div>
            <div>
              {<Square style={{ color: 'lightskyblue' }} fontSize='medium' />}{' '}
              Qualifying
            </div>
          </div>
          <div>
            <div className='projectColumnHeader'>{<Dvr />} Project column</div>
            <div>
              {<Square style={{ color: 'lightgreen' }} fontSize='medium' />}{' '}
              Ongoing project
            </div>
          </div>
        </div>
      </div>
      <div className='otherInfo'>
        <div className='customersWithOtherKeywords'>
          <div>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component='nav'
            >
              <ListItemButton onClick={handleSalesClick}>
                <ListItemText primary='Untagged sales cases' />
                {salesOpen ? <ExpandLess /> : <ExpandMore />}
                {salesWithNoMainKeyword.length > 0 ? (
                  <b style={{ color: 'red' }}>
                    {salesWithNoMainKeyword.length} kpl
                  </b>
                ) : (
                  <b style={{ color: 'black' }}>
                    {salesWithNoMainKeyword.length} kpl
                  </b>
                )}
              </ListItemButton>
              <Collapse in={salesOpen} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {salesWithNoMainKeyword.map((sales) => {
                    return (
                      <ListItemButton
                        key={sales.guid}
                        sx={{ pl: 4 }}
                        component={'a'}
                        href={`https://severa.visma.com/project/${sales.guid}/settings`}
                        target={'_blank'}
                      >
                        <ListItemText>{sales.salesName}</ListItemText>
                        <ListSubheader>{sales.customerName}</ListSubheader>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            </List>
          </div>
          <div>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component='nav'
            >
              <ListItemButton onClick={handleProjectsClick}>
                <ListItemText primary='Untagged project cases' />
                {projectsOpen ? <ExpandLess /> : <ExpandMore />}
                {projectsWithNoMainKeyword.length > 0 ? (
                  <b style={{ color: 'red' }}>
                    {projectsWithNoMainKeyword.length} kpl
                  </b>
                ) : (
                  <b style={{ color: 'black' }}>
                    {projectsWithNoMainKeyword.length} kpl
                  </b>
                )}
              </ListItemButton>
              <Collapse in={projectsOpen} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {projectsWithNoMainKeyword.map((project) => {
                    return (
                      <ListItemButton
                        key={project.guid}
                        sx={{ pl: 4 }}
                        component={'a'}
                        href={`https://severa.visma.com/project/${project.guid}/settings`}
                        target={'_blank'}
                      >
                        <ListItemText>{project.projectName}</ListItemText>
                        <ListSubheader>{project.name}</ListSubheader>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            </List>
          </div>
          <div>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component='nav'
            >
              <ListItemButton onClick={handleCustomersClick}>
                <ListItemText primary='Not classified Customers' />
                {customersOpen ? <ExpandLess /> : <ExpandMore />}
                {customersNotClassified.length > 0 ? (
                  <b style={{ color: 'red' }}>
                    {customersNotClassified.length} kpl
                  </b>
                ) : (
                  <b style={{ color: 'black' }}>
                    {customersNotClassified.length} kpl
                  </b>
                )}
              </ListItemButton>
              <Collapse in={customersOpen} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {customersNotClassified.map((customer) => {
                    return (
                      <ListItemButton
                        key={customer.guid}
                        sx={{ pl: 4 }}
                        component={'a'}
                        href={`https://severa.visma.com/customer/${customer.guid}/settings`}
                        target={'blank'}
                      >
                        <ListItemText>{customer.name}</ListItemText>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
